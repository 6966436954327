window.POPUPS = {
  scrollTop: 0,
};
// account
POPUPS.Account = {
  mouseover: true,
  elements: {
    accountLogin: document.querySelector('[data-role="accountLogin"]'),
    accountLoginToggleBtns: document.querySelectorAll(
      '[data-action="accountLogin"]'
    ),
    loginPopupContainer: document.querySelector('[data-role="popup-login"]'),
    recoverPopupContainer: document.querySelector(
      '[data-role="recover-popup"]'
    ),
    forgotPassToggleButton: document.querySelectorAll(
      '[data-role="forgot-passwrod"]'
    ),
  },
  init: function () {
    POPUPS.attachListeners.bind(
      this,
      this.elements.accountLoginToggleBtns,
      this.elements.accountLogin
    )();
    this.elements.forgotPassToggleButton.forEach((el) => {
      el.addEventListener("click", (e) => {
        e.preventDefault();
        this.forgotPassToggle.bind(this)(e);
      });
    });
  },
  forgotPassToggle: (evt) => {
    const accountCont = evt.target.closest('[data-role="accountLogin"]');
    const forgotPassCont = accountCont?.querySelector(
      '[data-role="recover-popup"]'
    );
    const accLoginCont = accountCont?.querySelector(
      '[data-role="popup-login"]'
    );

    if (forgotPassCont.classList.contains("hidden")) {
      forgotPassCont.classList.remove("hidden");
      accLoginCont.classList.add("hidden");
    } else {
      forgotPassCont.classList.add("hidden");
      accLoginCont.classList.remove("hidden");
    }
  },
};

// wishlist
POPUPS.Wishlist = {
  mouseover: true,
  uuid: "",
  key: window.hulkApp.key,
  elements: {
    wishlist: document.querySelector('[data-role="wishlist"]'),
    wishlistToggleBtns: document.querySelectorAll('[data-action="wishlist"]'),
  },
  customerId: document.querySelector('[data-role="wishlist"]').dataset.customer,
  init: function () {
    window.wishlist = [];
    this.uuid = document.cookie.includes("hulk_wishlist_UserUUID")
      ? document.cookie.split("hulk_wishlist_UserUUID=")[1].split(";")[0]
      : false;
    POPUPS.attachListeners.bind(
      this,
      this.elements.wishlistToggleBtns,
      this.elements.wishlist
    )();
    // guest wishlist data
    this.uuid && !this.customerId ? this.getWishlistProducts.bind(this)() : "";
    // customer wishlist data
    this.uuid && this.customerId
      ? this.syncWishlistItems.bind(this)({
          uuid: this.uuid,
          customerId: this.customerId,
        })
      : "";
    this.customerId ? this.getCustomerProducts.bind(this)() : "";
  },
  /////////////// Guest wishlist functions

  // get wishlist products
  getWishlistProducts(display = false) {
    const url = new URL(
      "https://advanced-wishlist.hulkapps.com/api/public/v1/wishlist/products/guest"
    );

    let params = {
      "uuid": this.uuid,
    };
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );

    let headers = {
      "Authorization": `Bearer ${this.key}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        window.wishlist.products = data.data ? data.data : [];
        let wishlistUpdateEvent = new CustomEvent("wishlist-update");
        document.dispatchEvent(wishlistUpdateEvent);
        this.updateWishlistCount(window.wishlist.products.length);
        document.addEventListener(
          "load",
          this.renderWishlistProducts.bind(this)(false)
        );
      });
  },
  // add product to wishist
  addProductToWishist({ variantId, productId, productHandle }) {
    this.renderWishlistProducts.bind(this)(true, {
      add: true,
      product: {
        handle: productHandle,
        variant_id: variantId,
      },
    });
    const url = new URL(
      "https://advanced-wishlist.hulkapps.com/api/public/v1/wishlist/add_product/guest"
    );

    let headers = {
      "Authorization": `Bearer ${this.key}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    };

    let body = {
      "product_id": productId,
      "variant_id": variantId,
      "shop": Shopify.shop,
      "uuid": this.uuid || null,
    };

    fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        !this.uuid
          ? (document.cookie = `hulk_wishlist_UserUUID=${data.data.uuid};path=/`)
          : "";
        this.uuid = data.data.uuid;
        this.getWishlistProducts.bind(this)(true);
      });
  },
  //// remove product from wishlist
  removeWishlistItem(data) {
    // this function is used outside of wishlist so data passed in parameter are as follows
    // key
    // productId
    // variantId
    // uuid
    // wrapper optional
    let wrapper = data.wrapper;
    let display = data.display;
    this.renderWishlistProducts.bind(this)(display, {
      add: false,
      product: {
        handle: data.productHandle,
        variant_id: data.variantId,
      },
    });
    const url = new URL(
      "https://advanced-wishlist.hulkapps.com/api/public/v1/wishlist/delete_product/guest"
    );

    let headers = {
      "Authorization": `Bearer ${data.key}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    };

    let body = {
      "product_id": data.productId,
      "variant_id": data.variantId,
      "uuid": data.uuid,
    };

    fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        POPUPS.Wishlist.getWishlistProducts(display);
        wrapper ? wrapper.classList.remove("loading") : "";
      });
  },

  ///// Customer wishlist functions
  // get wishlist product
  getCustomerProducts(display = false) {
    const url = new URL(
      "https://advanced-wishlist.hulkapps.com/api/public/v1/wishlist/products"
    );

    let params = {
      "customer_id": this.customerId,
    };
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );

    let headers = {
      "Authorization": `Bearer ${this.key}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        window.wishlist.products = data.data ? data.data : [];
        let wishlistUpdateEvent = new CustomEvent("wishlist-update");
        document.dispatchEvent(wishlistUpdateEvent);
        this.updateWishlistCount(window.wishlist.products.length);
        document.addEventListener(
          "load",
          this.renderWishlistProducts.bind(this)(false)
        );
      });
  },
  // add wishlist product
  addCustomerProduct({ variantId, productId, productHandle }) {
    this.renderWishlistProducts.bind(this)(true, {
      add: true,
      product: {
        handle: productHandle,
        variant_id: variantId,
      },
    });
    const url = new URL(
      "https://advanced-wishlist.hulkapps.com/api/public/v1/wishlist/add_product"
    );

    let headers = {
      "Authorization": `Bearer ${this.key}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    };

    let body = {
      "product_id": productId,
      "variant_id": variantId,
      "customer_id": this.customerId,
      "wishlist_ids": null,
      "uuid": null,
    };

    fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        this.getCustomerProducts.bind(this)(true);
      });
  },

  /// remove customer product
  removeCustomerProduct(data) {
    // this function is used outside of wishlist so data passed in parameter are as follows
    // key
    // productId
    // variantId
    // uuid
    // wrapper optional
    let wrapper = data.wrapper;
    let display = data.display;
    this.renderWishlistProducts.bind(this)(display, {
      add: false,
      product: {
        handle: data.productHandle,
        variant_id: data.variantId,
      },
      wrapper,
    });
    const url = new URL(
      "https://advanced-wishlist.hulkapps.com/api/public/v1/wishlist/delete_product"
    );

    let headers = {
      "Authorization": `Bearer ${data.key}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    };

    let body = {
      "product_id": data.productId,
      "variant_id": data.variantId,
      "customer_id": POPUPS.Wishlist.customerId,
    };

    fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        POPUPS.Wishlist.getCustomerProducts(display);
      });
  },
  /// render wishlist popup products
  /*
  params
    display - for popup display
    data - {
      add :true/false
      product :{
        productHandle,
        variantId
      }
    }
  */
  renderWishlistProducts(display = false, data) {
    let wishlistItems = window.wishlist.products
      ? window.wishlist.products.map((item) => ({
          handle: item.handle,
          variant_id: item.variant_id,
        }))
      : [];
    if (data) {
      wishlistItems = data.add
        ? [data.product, ...wishlistItems]
        : wishlistItems.filter((el) => el.handle != data.product.handle);
    }
    let url =
      Shopify.locale == "ar"
        ? "/ar/search?view=wishlist-ajax&q="
        : "/search?view=wishlist-ajax&q=";
    wishlistItems.forEach((el) => {
      let string = `${el.handle}:${el.variant_id},`;
      url += string;
    });
    fetch(url)
      .then((res) => res.text())
      .then((text) => {
        let parser = new DOMParser();
        let wishlistPopupContent = parser
          .parseFromString(text, "text/html")
          .querySelector("[data-role='wishlistPopup-content']");
        let wishlistPageContent = parser
          .parseFromString(text, "text/html")
          .querySelector('[data-role="wishlistPage-items"]');
        let desktopToggleBtton = document.querySelector(
          `header [data-role="icons"] [data-action="wishlist"]`
        );
        document.querySelector(
          "[data-role='wishlistPopup-content']"
        ).innerHTML = "";
        document.querySelector(
          "[data-role='wishlistPopup-content']"
        ).innerHTML = wishlistPopupContent.innerHTML;

        document.querySelector('[data-role="wishlistPage-items"]')
          ? (document.querySelector(
              '[data-role="wishlistPage-items"]'
            ).innerHTML = "")
          : "";
        document.querySelector('[data-role="wishlistPage-items"]')
          ? (document.querySelector(
              '[data-role="wishlistPage-items"]'
            ).innerHTML = wishlistPageContent.innerHTML)
          : "";
        display ? this.popupDisplay.bind(this)(desktopToggleBtton) : "";
        data?.wrapper ? data.wrapper.classList.remove("loading") : "";
      });
  },

  ////////////// sync guest and loggedin customer wishlist
  syncWishlistItems({ uuid, customerId }) {
    const url = new URL(
      "https://advanced-wishlist.hulkapps.com/api/public/v1/wishlist/sync_products"
    );

    let headers = {
      "Authorization": `Bearer ${this.key}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    };

    let body = {
      "uuid": uuid,
      "customer_id": customerId,
    };

    fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        document.cookie =
          "hulk_wishlist_UserUUID=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      });
  },
  ////////////// guest wishlist end
  updateWishlistCount(count) {
    Array.from(
      document.querySelectorAll('[data-role="wishlist-count"]')
    ).forEach((el) => {
      // count > 0 ? el.classList.remove('hidden') : el.classList.add('hidden');
      count > 0
        ? el.closest('[data-action="wishlist"]').classList.add("active")
        : el.closest('[data-action="wishlist"]').classList.remove("active");
      el.textContent = count;
    });
  },
  // display wishlist popup
  popupDisplay(btn) {
    if (window.innerWidth < 768) return;
    let wishlistPopup = document.querySelector('[data-role="wishlist"]');
    wishlistPopup.classList.remove("is-hidden");
    setTimeout(() => {
      wishlistPopup.classList.add("is-active");
      btn.classList.add("show");
    }, 0);
    let timeout = setTimeout(() => {
      wishlistPopup.classList.remove("is-active");
      setTimeout(() => {
        wishlistPopup.classList.add("is-hidden");
        btn.classList.remove("show");
      }, 0);
    }, 3000);
    wishlistPopup.addEventListener("mouseenter", () => {
      clearTimeout(timeout);
    });
  },
};

//cart
POPUPS.Cart = {
  mouseover: true,
  elements: {
    cart: document.querySelector('[data-role="cart"]'),
    cartToggleBtns: document.querySelectorAll('[data-action="cart"]'),
    countBubbles: Array.from(
      document.querySelectorAll('[data-role="cart-count"]')
    ),
  },
  init: function () {
    POPUPS.attachListeners.bind(
      this,
      this.elements.cartToggleBtns,
      this.elements.cart
    )();
  },
  updateCountBubble: function (count) {
    this.elements.countBubbles.forEach((el) => {
      Number(count) > 0
        ? el.classList.remove("hidden")
        : el.classList.add("hidden");
      el.textContent = count;
    });
  },
  updateMiniCart: function (display = false, loader = false) {
    let sectionId = this.elements.cart.dataset.section;
    let cartPopup = document.querySelector('[data-role="cart"]');
    let desktopToggleBtn = document.querySelector(
      'header [data-role="icons"] [data-action="cart"]'
    );
    fetch(window.Shopify.routes.root + `?sections=${sectionId}`)
      .then((res) => res.json())
      .then((data) => {
        let htmlString = data[sectionId];
        let parser = new DOMParser();
        let miniCartHtml = parser.parseFromString(htmlString, "text/html");
        this.elements.cart.querySelector(
          '[data-role="mini-cart-content"]'
        ).innerHTML = miniCartHtml
          .querySelector('[data-role="cart"]')
          .querySelector('[data-role="mini-cart-content"]').innerHTML;
        this.updateCountBubble.bind(this)(
          miniCartHtml.querySelector('[data-role="cart"]').dataset.count
        );
        if (display && window.innerWidth >= 768) {
          cartPopup.classList.remove("is-hidden");
          setTimeout(() => {
            cartPopup.classList.add("is-active");
            desktopToggleBtn.classList.add("show");
          }, 300);
          setTimeout(() => {
            if (!this.mouseover) {
              cartPopup.classList.remove("is-active");
              setTimeout(() => {
                cartPopup.classList.add("is-hidden");
                desktopToggleBtn.classList.remove("show");
              }, 300);
            }
          }, 3000);
        }
        if (loader) this.elements.cart.classList.remove("loading");
      });
  },
  miniCartRemoveItem: function () {},
};

// customerService
POPUPS.CustomerService = {
  mouseover: true,
  elements: {
    customerService: document.querySelector('[data-role="customerService"]'),
    customerServiceToggleBtns: document.querySelectorAll(
      '[data-action="customerService"]'
    ),
  },
  init: function () {
    POPUPS.attachListeners.bind(
      this,
      this.elements.customerServiceToggleBtns,
      this.elements.customerService
    )();
  },
};

// search bar
POPUPS.SearchBar = {
  elements: {
    searchBar: document.querySelector('[data-role="search-input"]'),
    collectionMobFilteres: document.querySelector(
      '[data-role="filter-sticky-mobile"]'
    ),
    searchToggleBtns: Array.from(
      document.querySelectorAll('[data-action="search-input"]')
    ),
  },
  searchButtonClicked: false,
  init() {
    this.elements.searchToggleBtns.forEach((el) => {
      el.addEventListener("click", (e) => {
        e.preventDefault();
        this.searchButtonClicked = true;
        this.searchDisplay();
        setTimeout(() => {
          this.searchButtonClicked = false;
        }, 400);
      });
    }),
      window.addEventListener("scroll", () => {
        !this.searchButtonClicked && window.scrollY > 100
          ? this.searchDisplay.bind(this, true)()
          : "";
      });
    document.addEventListener("click", (e) => {
      if (
        e.target.dataset.role != "search-input" &&
        !e.target.closest('[data-role="search-input"]') &&
        e.target.dataset.action != "search-input" &&
        !e.target.closest('[data-action="search-input"]')
      ) {
        this.searchDisplay.bind(this, true)();
      }
    });
    this.elements.searchBar
      .querySelector("form")
      .addEventListener("submit", this.formSubmissionHandler.bind(this));
  },
  formSubmissionHandler(e) {
    e.preventDefault();
    let submitEvent = new CustomEvent("submit");
    let formData = new URLSearchParams(
      new FormData(this.elements.searchBar.querySelector("form"))
    );
    let query = formData.get("q");
    let url = `${Shopify.routes.root}search?${formData.toString()}`;
    query.length > 0 ? (window.location = url) : "";
  },
  searchDisplay(hide = false) {
    let currentDisplay = getComputedStyle(this.elements.searchBar).display;
    let icons = document.querySelector('[data-role="icons"]');
    let langSelector = document.querySelector('[data-role="lang-selector"]');
    let nav = document.querySelector('[data-role="navigation"]');
    if (currentDisplay == "none" && !hide) {
      this.elements.searchBar.style.display = "block";
      this.elements.searchBar.style.zIndex = "100";
      this.elements.searchBar.style.position = "fixed";
      window.innerWidth < 768
        ? (this.elements.searchBar.style.top = "0px")
        : (this.elements.searchBar.style.top = "unset");
      window.innerWidth >= 768 ? (icons.style.opacity = "0") : "";
      window.innerWidth >= 768 && langSelector
        ? (langSelector.style.opacity = "0")
        : "";
      window.innerWidth < 768 ? (nav.style.display = "none") : "";
      window.innerWidth < 768 && this.elements.collectionMobFilteres
        ? (this.elements.collectionMobFilteres.style.top = `${this.elements.searchBar.clientHeight}px`)
        : "";

      this.elements.searchBar.style.marginTop =
        window.innerWidth >= 1024 ? `-${nav.clientHeight}px` : "0px";
      setTimeout(() => {
        this.elements.searchBar.style.opacity = "1";
      }, 0);
      window.innerWidth < 768
        ? (document.querySelector(
            "#shopify-section-announcement-bar"
          ).style.zIndex = "100")
        : "";
    } else {
      this.elements.searchBar.style.opacity = "0";
      this.elements.searchBar.style.display = "none";
      this.elements.searchBar.style.zIndex = "inherit";
      window.innerWidth >= 768 ? (icons.style.opacity = "1") : "";
      window.innerWidth >= 768 && langSelector
        ? (langSelector.style.opacity = "1")
        : "";
      window.innerWidth < 768 ? nav.removeAttribute("style") : "";
      window.innerWidth < 768 && this.elements.collectionMobFilteres
        ? (this.elements.collectionMobFilteres.style.top = `${
            document.querySelector('[data-role="navigation"]').clientHeight +
            document.querySelector('[data-role="announcement"]').clientHeight
          }px`)
        : "";
      window.innerWidth >= 768
        ? (document.querySelector(
            '[data-action="search-input"]'
          ).style.opacity = "1")
        : "";
      window.innerWidth < 768
        ? (document.querySelector(
            "#shopify-section-announcement-bar"
          ).style.zIndex = "101")
        : "";
    }
  },
};
// poups display toggle
POPUPS.togglePopupDisplay = function (el) {
  let popupElement = document.querySelector(
    `[data-role='${el.dataset.action}']`
  );
  if (window.innerWidth <= 1023) {
    if (popupElement.classList.contains("is-hidden")) {
      popupElement.classList.remove("is-hidden");
      window.innerWidth < 768 ? (document.body.style.overflow = "hidden") : "";
      setTimeout(() => {
        popupElement.classList.add("is-active");
        el.classList.add("show");
      }, 0);
    } else {
      popupElement.classList.remove("is-active");
      window.innerWidth < 768 ? (document.body.style.overflow = "auto") : "";
      setTimeout(() => {
        popupElement.classList.add("is-hidden");
        el.classList.remove("show");
      }, 0);
    }
  } else {
    if (this.mouseover == true) {
      popupElement.classList.remove("is-hidden");
      setTimeout(() => {
        popupElement.classList.add("is-active");
        el.classList.add("show");
      }, 0);
    } else {
      popupElement.classList.remove("is-active");
      setTimeout(() => {
        popupElement.classList.add("is-hidden");
        el.classList.remove("show");
      }, 0);
    }
  }
};

// attacing listeners
POPUPS.attachListeners = function (toggleBtns, popup) {
  if (window.innerWidth > 1023) {
    Array.from(toggleBtns).forEach((el) =>
      el.addEventListener("mouseover", () => {
        this.mouseover = true;
        POPUPS.togglePopupDisplay.bind(this, el)();
      })
    );
    Array.from(toggleBtns).forEach((el) =>
      el.addEventListener("mouseleave", () => {
        let removeOver = setTimeout(() => {
          this.mouseover = false;
          POPUPS.togglePopupDisplay.bind(this, el)();
        }, 100);
        popup.addEventListener("mouseover", () => {
          clearTimeout(removeOver);
          this.mouseover = true;
        });
      })
    );
    popup.addEventListener("mouseleave", () => {
      let toggleBtn =
        document.querySelector(
          `[data-role="icons"] [data-action="${popup.dataset.role}"]`
        ) ||
        document.querySelector(
          `[data-role="announcement"] [data-action="${popup.dataset.role}"]`
        );
      this.mouseover = false;
      POPUPS.togglePopupDisplay.bind(this, toggleBtn)();
    });
  } else {
    Array.from(toggleBtns).forEach((el) =>
      el.addEventListener("click", POPUPS.togglePopupDisplay.bind(this, el))
    );
  }
};

POPUPS.Account.init();
POPUPS.Wishlist.init();
POPUPS.Cart.init();
POPUPS.CustomerService.init();
POPUPS.SearchBar.init();

// mini cart remove btn

class ItemRemoveBtn extends HTMLElement {
  constructor() {
    super();
    let key = this.dataset.key;
    this.addEventListener("click", (e) => {
      e.preventDefault();
      // dispatching event for mini cart update
      let removeEvent = new CustomEvent("mini-item-removed", {
        detail: { key },
      });
      document.dispatchEvent(removeEvent);
      ///
      if (
        Array.from(
          this.closest('[data-role="cart"]').querySelectorAll(
            '[data-role="mini-item"]'
          )
        ).length > 1
      ) {
        this.closest('[data-role="mini-item"]').style.opacity = "0";
        this.closest('[data-role="mini-item"]').style.maxHeight = "0px";
        this.closest('[data-role="mini-item"]').style.padding = "0px";
        this.closest('[data-role="mini-item"]').style.margin = "0px";
      }
      this.removeItem(key);
    });
  }
  removeItem = (key) => {
    this.closest('[data-role="cart"]').classList.add("loading");
    fetch("/cart/update.js", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        updates: { [key]: 0 },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        POPUPS.Cart.updateMiniCart(false, true);
      });
  };
}
customElements.define("item-remove-btn", ItemRemoveBtn);

//show password icon script
class PasswordInput extends HTMLElement {
  constructor() {
    super();
    this.passInput = this.querySelector('input[type="password"]');
    this.toggleBtn = this.querySelector('[data-role="show-password"]');
    this.toggleBtn?.addEventListener("click", () => {
      this.passInput.type =
        this.passInput.type == "password" ? "text" : "password";
    });
  }
}
customElements.define("password-input", PasswordInput);
//End password icon script

// forget pass form
class ForgetPassPopup extends HTMLElement {
  constructor() {
    super();
    this.form = this.querySelector("form");
    this.emailInput = this.querySelector('[data-role="password"]');
    this.submissionButton = this.querySelector("button[type='submit']");
    this.emailInput.addEventListener(
      "input",
      this.checkEmailValidation.bind(this)
    );
    this.emailInput.addEventListener(
      "focusout",
      this.checkEmailValidation.bind(this)
    );
    this.form.addEventListener("submit", (e) => {
      e.preventDefault();
      this.submissionHandler.bind(this)();
    });
  }
  checkEmailValidation() {
    let emailVal = this.emailInput.value;
    let emailRegex =
      /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    let valid = emailRegex.test(emailVal);
    valid
      ? this.emailInput.closest("fieldset").classList.remove("error")
      : this.emailInput.closest("fieldset").classList.add("error");
    valid
      ? this.submissionButton.removeAttribute("disabled")
      : this.submissionButton.setAttribute("disabled", "disabled");
  }
  submissionHandler(e) {
    this.classList.add("loading");
    let formData = new URLSearchParams(new FormData(this.form)).toString();
    fetch(this.form.action, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formData,
    })
      .then((res) => res.text())
      .then((text) => {
        let parser = new DOMParser();
        let html = parser.parseFromString(text, "text/html");
        let error = html.querySelector('[data-role="forgotError-field"]');
        if (error) {
          this.classList.add("error");
          this.classList.remove("loading");
        } else {
          this.classList.add("success");
          this.classList.remove("loading");
        }
      });
  }
}
customElements.define("forgotpass-popup", ForgetPassPopup);

// login popup form submission

class LoginPopup extends HTMLElement {
  constructor() {
    super();
    this.form = this.querySelector("form");
    this.emailInput = this.querySelector('input[name="customer[email]"]');
    this.passwordInput = this.querySelector('input[name="customer[password]"]');
    this.errorField = this.querySelector('[data-role="error-field"]');
    this.submissionButton = this.querySelector('button[type="submit"]');

    this.form.addEventListener("submit", this.formSubmission.bind(this));
    this.emailInput.addEventListener("input", this.emailValid.bind(this));
    this.emailInput.addEventListener("focusout", this.emailValid.bind(this));
    this.passwordInput.addEventListener("input", this.passValid.bind(this));
    this.passwordInput.addEventListener("focusout", this.passValid.bind(this));
  }
  emailValid() {
    let emailRegex =
      /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    let emailValue = this.emailInput.value;
    emailRegex.test(emailValue)
      ? (this.emailValidation = true)
      : (this.emailValidation = false);
    this.emailValidation
      ? this.emailInput.closest("div").classList.remove("error")
      : this.emailInput.closest("div").classList.add("error");
    this.emailValidation && this.passValidation
      ? this.submissionButton.closest("div").removeAttribute("disabled")
      : this.submissionButton
          .closest("div")
          .setAttribute("disabled", "disabled");
  }
  passValid() {
    let passValue = this.passwordInput.value;
    passValue.trim().length < 1
      ? (this.passValidation = false)
      : (this.passValidation = true);
    this.passValidation
      ? this.passwordInput.closest("div").classList.remove("error")
      : this.passwordInput.closest("div").classList.add("error");
    this.emailValidation && this.passValidation
      ? this.submissionButton.removeAttribute("disabled")
      : this.submissionButton.setAttribute("disabled", "disabled");
  }
  // form submission
  formSubmission(e) {
    e.preventDefault();
    this.classList.add("loading");
    let formData = new URLSearchParams(new FormData(this.form)).toString();
    fetch(this.form.action, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formData,
    })
      .then((res) => res.text())
      .then((htmlText) => {
        let parser = new DOMParser();
        let loginHtml = parser.parseFromString(htmlText, "text/html");
        let errors = loginHtml.querySelector('[data-role="errors"]');
        if (errors) {
          this.errorField.classList.remove("hidden");
          this.classList.remove("loading");
        } else {
          this.classList.remove("loading");
          this.errorField.classList.add("hidden");
          this.classList.add("success");
          setTimeout(() => {
            window.location.href = `${Shopify.routes.root}`;
          }, 500);
        }
        errors ? console.log("errors", errors) : console.log("no errors found");
      });
  }
}
customElements.define("login-popup", LoginPopup);

/////////////////// wishlist

class WishlistBtn extends HTMLElement {
  constructor() {
    super();
    (this.productId = this.dataset.productId),
      document.addEventListener("wishlist-update", () => {
        let wishistProducts = window.wishlist.products;
        let wishlistItem = wishistProducts.find(
          (el) => el.product_id == this.productId
        );
        wishlistItem
          ? this.classList.add("active")
          : this.classList.remove("active");
        wishlistItem ? (this.dataset.variantId = wishlistItem.variant_id) : "";
      });
    this.addEventListener("click", () => {
      let data = {
        variantId: this.dataset.variantId,
        productId: this.dataset.productId,
        productHandle: this.dataset.productHandle,
        key: POPUPS.Wishlist.key,
        uuid: POPUPS.Wishlist.uuid,
        display: true,
      };
      if (this.classList.contains("active")) {
        this.classList.remove("active");
        POPUPS.Wishlist.customerId
          ? POPUPS.Wishlist.removeCustomerProduct(data)
          : POPUPS.Wishlist.removeWishlistItem(data);
      } else {
        this.classList.add("active");
        POPUPS.Wishlist.customerId
          ? POPUPS.Wishlist.addCustomerProduct(data)
          : POPUPS.Wishlist.addProductToWishist(data);
      }
    });
  }
}
customElements.define("wishlist-btn", WishlistBtn);

class WishlistRemoveBtn extends HTMLElement {
  constructor() {
    super();
    this.item = this.closest('[data-role="wishlist-item"]');
    this.wrapper = this.closest('[data-role="wishlist"]');
    this.variantId = this.dataset.variantid;
    this.productId = this.dataset.productid;
    this.productHandle = this.dataset.productHandle;
    this.key = POPUPS.Wishlist.key;
    this.uuid = POPUPS.Wishlist.uuid;
    this.data = {
      key: this.key,
      uuid: this.uuid,
      productId: this.productId,
      productHandle: this.productHandle,
      variantId: this.variantId,
      wrapper: this.wrapper,
    };
    this.addEventListener("click", (e) => {
      e.preventDefault();
      this.removeItem.bind(this)();
    });
  }
  removeItem() {
    this.wrapper.classList.add("loading");
    if (this.item) {
      this.item.style.maxHeight = "0px";
      setTimeout(() => {
        this.item.style.padding = "0px";
        this.item.style.opacity = "0";
        this.item.style.overflow = "hidden";
      });
    }
    POPUPS.Wishlist.customerId
      ? POPUPS.Wishlist.removeCustomerProduct(this.data)
      : POPUPS.Wishlist.removeWishlistItem(this.data);
  }
}
customElements.define("wishlist-remove-btn", WishlistRemoveBtn);

class WishlistSizeBtn extends HTMLElement {
  constructor() {
    super();
    this.id = this.dataset.id;

    this.addEventListener("click", () => {
      this.color = this.closest("wishlist-item")
        ? this.closest("wishlist-item").querySelector('[data-role="colorProp"]')
            ?.value
        : this.closest('[data-role="wishlist-item"]')?.querySelector(
            '[data-role="colorProp"]'
          )?.value;
      this.sizeForm = Array.from(
        document.querySelectorAll(`size-form[data-id="${this.id}"]`)
      );
      this.sizeForm.length == 0 && this.dataset.available == "true"
        ? this.addItemToCart.bind(this)({
            id: this.dataset.variant,
            color: this.color,
          })
        : "";
      this.sizeForm.length == 0 && this.dataset.available == "false"
        ? this.displayNotifyForm.bind(this)()
        : "";
      this.sizeForm.forEach((el) => {
        el.classList.contains("active")
          ? el.classList.remove("active")
          : el.classList.add("active");
      });
    });
  }
  addItemToCart(data) {
    fetch("/cart/add.js", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        items: [
          {
            id: data.id,
            quantity: 1,
            properties: {
              color: data.color,
            },
          },
        ],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status == 422) {
          this.showError.bind(this)();
          this.showError(data.description);
        } else {
          this.hideError.bind(this)();
          POPUPS.Cart.updateMiniCart(true, false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  showError() {
    let parent =
      this.closest('[data-role="wishlist-item"]') ||
      this.closest("wishlist-item");
    Array.from(parent.querySelectorAll('[data-role="add-error"]')).forEach(
      (el) => {
        el.classList.remove("hidden");
        setTimeout(() => {
          el.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }, 0);
      }
    );
  }
  hideError() {
    let parent =
      this.closest('[data-role="wishlist-item"]') ||
      this.closest("wishlist-item");
    Array.from(parent.querySelectorAll('[data-role="add-error"]')).forEach(
      (el) => {
        el.classList.add("hidden");
      }
    );
  }
  displayNotifyForm() {
    let id = this.dataset.id;
    let notifyForm = Array.from(
      document.querySelectorAll(`wishlist-notifyform[data-id="${id}"]`)
    );
    notifyForm.forEach((el) => {
      el.classList.contains("active")
        ? el.classList.remove("active")
        : el.classList.add("active");
    });
  }
}
customElements.define("size-selector", WishlistSizeBtn);

class WishlistSizeForm extends HTMLElement {
  constructor() {
    super();
    this.id = this.dataset.id;
    this.variantBtns = Array.from(
      this.querySelectorAll('[data-role="variant-selector"]')
    );
    this.data = JSON.parse(
      this.querySelector(`script[data-id='${this.id}-data']`).innerHTML
    );
    this.variantBtns.forEach((el) => {
      el.addEventListener("click", () => {
        this.color = this.closest("wishlist-item")
          ? this.closest("wishlist-item").querySelector(
              '[data-role="colorProp"]'
            )?.value
          : this.closest('[data-role="wishlist-item"]')?.querySelector(
              '[data-role="colorProp"]'
            )?.value;
        this.updateSelectedVariant.bind(this)(el);
      });
    });
  }
  updateSelectedVariant(btn) {
    btn.classList.add("active");
    let optionValue = btn.dataset.value;
    let variantData = this.data.find(
      (el) => el.options.indexOf(optionValue) != -1
    );

    this.variantBtns.forEach((el) => {
      el !== btn ? el.classList.remove("active") : "";
    });
    btn.dataset.available == "true"
      ? this.addItemToCart.bind(this)({ id: variantData.id, color: this.color })
      : this.displayNotifyForm.bind(this)(variantData.id);
  }
  addItemToCart(data) {
    fetch("/cart/add.js", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        items: [
          {
            id: data.id,
            quantity: 1,
            properties: {
              color: data.color,
            },
          },
        ],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status == 422) {
          this.showError.bind(this)();
        } else {
          POPUPS.Cart.updateMiniCart(true, false);
          this.hideError.bind(this)();
          this.classList.remove("active");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  showError() {
    let parent =
      this.closest('[data-role="wishlist-item"]') ||
      this.closest("wishlist-item");
    Array.from(parent.querySelectorAll('[data-role="add-error"]')).forEach(
      (el) => {
        el.classList.remove("hidden");
        console.log(el);
        setTimeout(() => {
          el.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }, 0);
      }
    );
  }
  hideError() {
    let parent =
      this.closest('[data-role="wishlist-item"]') ||
      this.closest("wishlist-item");
    Array.from(parent.querySelectorAll('[data-role="add-error"]')).forEach(
      (el) => {
        el.classList.add("hidden");
      }
    );
  }
  displayNotifyForm(variantId) {
    let id = this.dataset.id;
    let notifyForm = Array.from(
      document.querySelectorAll(`wishlist-notifyform[data-id="${id}"]`)
    );
    notifyForm.forEach((el) => {
      el.classList.contains("active")
        ? el.classList.remove("active")
        : el.classList.add("active");
      variantId ? (el.dataset.variantId = variantId) : "";
    });
  }
}
customElements.define("size-form", WishlistSizeForm);

class NotifyDisplayBtn extends HTMLElement {
  constructor() {
    super();
    this.addEventListener("click", this.displayNotifyForm.bind(this));
  }
  displayNotifyForm() {
    let id = this.dataset.id;
    let notifyForm = Array.from(
      document.querySelectorAll(`wishlist-notifyform[data-id="${id}"]`)
    );
    notifyForm.forEach((el) => {
      el.classList.contains("active")
        ? el.classList.remove("active")
        : el.classList.add("active");
    });
  }
}
customElements.define("notify-display", NotifyDisplayBtn);
////////////// Notify me button

class NotifyForm extends HTMLElement {
  constructor() {
    super();
    this.submitButton = this.querySelector('button[type="submit"]');
    this.form = this.querySelector("form");
    this.emailInput = this.querySelector('input[name="email"]');
    this.emailInput.addEventListener(
      "input",
      this.validateInputField.bind(this)
    );
    this.form.addEventListener("submit", this.handleSubmission.bind(this));
    window.addEventListener("variant-change", this.resetForm.bind(this));
  }
  validateInputField() {
    let emailRegex =
      /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    emailRegex.test(this.emailInput.value)
      ? this.emailInput.classList.remove("error")
      : this.emailInput.classList.add("error");
    emailRegex.test(this.emailInput.value)
      ? this.submitButton.removeAttribute("disabled")
      : this.submitButton.setAttribute("disabled", "disabled");
  }
  getCurrentlySelectedData() {
    let data = {
      email: this.emailInput.value,
      productId: window.productData.product.id,
      variantId: window.productData.selectedVaraint.id,
    };
    return data;
  }
  handleSubmission() {
    this.classList.add("loading");
    let data = this.getCurrentlySelectedData();
    this.createEmailNotify.bind(this)(data);
  }
  hideForm() {
    this.closest('[data-role="notify-me"]')?.classList.add("hidden");
    this.closest('[data-role="notify-me"]')?.classList.contains("active")
      ? this.closest('[data-role="notify-me"]').classList.remove("active")
      : "";
    if (this.closest("product-info")) {
      Array.from(this.closest("product-info").querySelectorAll(".pdp-swatch"))
        .length > 0
        ? Array.from(
            this.closest("product-info").querySelectorAll(".pdp-swatch")
          ).forEach((el) => el.classList.remove("active"))
        : "";
      if (this.closest("product-info").querySelector(".pdp-sizeSelector-btn")) {
        this.closest("product-info")
          .querySelector(".pdp-sizeSelector-btn")
          ?.classList.remove("hidden");
        this.closest("product-info").querySelector(
          ".desktop-sticky-bar .pdp-sizeSelector-btn"
        )
          ? this.closest("product-info")
              .querySelector(".desktop-sticky-bar .pdp-sizeSelector-btn")
              .classList.remove("hidden")
          : "";
        this.closest("product-info").querySelector(
          ".desktop-sticky-bar .pdp-sizeSelector-btn"
        )
          ? this.closest("product-info")
              .querySelector(".desktop-sticky-bar .pdp-notify")
              .classList.add("hidden")
          : "";
        this.closest("product-info").querySelector(
          ".desktop-sticky-bar .pdp-sizeSelector-btn"
        )
          ? this.closest("product-info")
              .querySelector(".desktop-size-swatch")
              .classList.remove("active")
          : "";
      }
    }
  }
  resetForm() {
    this.emailInput.value = "";
    this.classList.remove("error");
    this.classList.remove("success");
  }
  showError(errorText) {
    this.classList.add("error");
    this.querySelector('[data-role="error"]').textContent = errorText;
    this.classList.remove("loading");
  }
  showSuccess() {
    this.classList.remove("error");
    this.classList.remove("loading");
    this.classList.add("success");
    setTimeout(() => {
      this.classList.remove("success");
      setTimeout(() => {
        this.hideForm.bind(this)();
      }, 0);
    }, 3000);
  }
  createEmailNotify(data) {
    BIS.create(data.email, data.variantId, data.productId).then((resp) => {
      if (resp.status == "OK") {
        this.showSuccess();
      } else {
        let errorText = resp.errors.base[0];
        this.showError(errorText);
      }
    });
  }
}
customElements.define("notify-form", NotifyForm);

// wishlist notify form
class WishlistNotifyForm extends HTMLElement {
  constructor() {
    super();
    this.submitButton = this.querySelector('button[type="submit"]');
    this.form = this.querySelector("form");
    this.emailInput = this.querySelector('input[name="email"]');

    this.emailInput.addEventListener(
      "input",
      this.validateInputField.bind(this)
    );
    this.form.addEventListener("submit", (e) => {
      e.preventDefault();
      this.handleSubmission.bind(this)();
    });
    window.addEventListener("variant-change", this.resetForm.bind(this));
  }
  validateInputField() {
    let emailRegex =
      /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    emailRegex.test(this.emailInput.value)
      ? this.emailInput.classList.remove("error")
      : this.emailInput.classList.add("error");
    emailRegex.test(this.emailInput.value)
      ? this.submitButton.removeAttribute("disabled")
      : this.submitButton.setAttribute("disabled", "disabled");
  }
  getCurrentlySelectedData() {
    let data = {
      email: this.emailInput.value,
      productId: this.dataset.id,
      variantId: this.dataset.variantId,
    };
    return data;
  }
  handleSubmission() {
    this.classList.add("loading");
    let data = this.getCurrentlySelectedData();
    this.createEmailNotify.bind(this)(data);
  }
  resetForm() {
    this.emailInput.value = "";
    this.classList.remove("error");
    this.classList.remove("success");
  }
  showError(errorText) {
    this.classList.add("error");
    this.querySelector('[data-role="error"]').textContent = errorText;
    this.classList.remove("loading");
  }
  hideForm() {
    this.classList.remove("active");
  }
  showSuccess() {
    this.classList.remove("error");
    this.classList.remove("loading");
    this.classList.add("success");
    setTimeout(() => {
      this.classList.remove("success");
      setTimeout(() => {
        this.hideForm.bind(this)();
      }, 0);
    }, 3000);
  }
  createEmailNotify(data) {
    BIS.create(data.email, data.variantId, data.productId).then((resp) => {
      if (resp.status == "OK") {
        this.showSuccess();
      } else {
        let errorText = resp.errors.base[0];
        this.showError(errorText);
      }
    });
  }
}
customElements.define("wishlist-notifyform", WishlistNotifyForm);
